import React, { useState } from 'react';
import Resume from '../components/Resume';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  return (
    <>
      <Resume />
    </>
  );
};
